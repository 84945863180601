import React from 'react';

import { About, Footer, Header, Skills, Testimonial, Work } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => (
  <div className="app">
    <Navbar />
    <section>
    <Header />
    </section>
    <section>
    <About />
    </section>
    <section>
    <Work />
    </section>
    <section>
    <Skills />
    </section>
    <section>
    <Testimonial />
    </section>
    <section>
    <Footer />
    </section>
  </div>
);

export default App;
