import React from 'react';
import './About.scss';
import { AppWrap, MotionWrap } from '../../wrapper';

const About = () => {
  return (
    <>
      <div id="app_about">
        <h2 className='head-text'>About</h2>
        <div id="circle">
          <div className="text-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 300 300"
              enableBackground="new 0 0 300 300"
              className="rotating-svg"
            >
              <defs>
                <path
                  id="circlePath"
                  d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                />
              </defs>
              <circle cx="150" cy="100" r="75" fill="none" />
              <text fill="#000" text-anchor="middle" dy="0.35em">
                <textPath xlinkHref="#circlePath">
                  Scott Ian Robertson - Web Developer
                </textPath>
              </text>
            </svg>
          </div>
          <div className="profile-circle"></div>
        </div>
        <p>I am a frontend developer and freelancer. I have spent many, many hours making web development projects.</p>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
