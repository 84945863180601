import React from 'react';
import { LuLinkedin } from "react-icons/lu";
import { FiGithub } from "react-icons/fi";
import { SiHashnode } from "react-icons/si";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href='https://github.com/ScottIanRobertson'><FiGithub /></a>
    </div>
    <div>
      <a href='https://scottianrobertson.hashnode.dev/'><SiHashnode /></a>
    </div>
    <div>
      <a href='https://www.linkedin.com/in/scottianrobertson/'><LuLinkedin /></a>
    </div>
  </div>
);

export default SocialMedia;
