import React from 'react';

import { AppWrap } from '../../wrapper';
import './Header.scss';

const Header = () => (
  <>
  	<div className="container">
		<div className="text-box">
    <h1>Hi I'm SCOTT</h1>
    <h1>This is my portfolio</h1>
		</div>
	</div>
</>
);

export default AppWrap(Header, 'home');
